@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400;500;700&family=Roboto:wght@100;400;500;700&display=swap');

:root {
  --accent-1: #3448FD;
  --background-light: #F8F7F4;
  --background-white: #FFF;
  --background-action-button: rgb(63, 61, 86);
}

.App-header {
  background-color: var(--background-light);
  font-size: calc(10px + 2vmin);
  container: fluid;
}

.App-header .nav-item button {
  color: var(--accent-1) !important;
}

.App-header .nav-item button:hover,
.App-header .nav-item button:focus {
  color: rgba(52, 72, 253, 0.8) !important;
}

.in-line {
  white-space: pre-line
}

.header2 {
  padding-top: 20px;
}

.image {
  padding-bottom: 20px;
}

.px-6 {
  padding-left: 20rem !important;
  padding-right: 20rem !important;
}

.fullHeight {
  min-height: calc(100vh - 4.5rem);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter", sans-serif;
}

p,
a {
  font-family: "Roboto", sans-serif;
}

.section-light {
  background-color: var(--background-light);
}

a {
  color: var(--accent-1) !important;
}

.text-accent {
  color: var(--accent-1) !important;
}

.bg-accent {
  background-color: var(--accent-1) !important;
}

.actionButton {
  background-color: var(--background-action-button);
  max-height: 12rem !important;
}

.actionButton:hover,
.actionButton:focus,
.actionButton:focus-within {
  background-color: rgba(63, 61, 86, 0.9) !important;
}

.wave-up,
.wave-down {
  position: relative;
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
}

.wave-down::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-repeat: repeat;
  height: 10px;
  background-size: 20px 20px;
  background-image:
    radial-gradient(circle at 10px -5px, transparent 12px, var(--background-white) 13px);
}

.wave-down::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-repeat: repeat;
  height: 15px;
  background-size: 40px 20px;
  background-image:
    radial-gradient(circle at 10px 15px, var(--background-white) 12px, transparent 13px);
}

.wave-up::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-repeat: repeat;
  height: 10px;
  background-size: 20px 20px;
  background-image:
    radial-gradient(circle at 10px -5px, transparent 12px, var(--background-light) 13px);
}

.wave-up::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-repeat: repeat;
  height: 15px;
  background-size: 40px 20px;
  background-image:
    radial-gradient(circle at 10px 15px, var(--background-light) 12px, transparent 13px);
}